<template>
  <div>
    <a-card title="出库订单">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />返回 </a-button>
      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
          <a-row>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="external_number" label="订单号">
                <a-input v-model="form.external_number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="customer_contact" label="客户联系人">
                <DeliveryCustomInput v-model="form.customer_contact" @select="selectDeliveryClient" />
                <!-- <a-input v-model="form.customer_contact" allowClear /> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="contact_phone" label="联系人电话">
                <a-input v-model="form.contact_phone" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="receiving_address" label="送货地址">
                <a-input v-model="form.receiving_address" allowClear />
              </a-form-model-item>
            </a-col>

            <!-- <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="province" label="省">
                <a-select v-model="form.province" allowClear style="width: 100%">
                  <a-select-option v-for="item in provinceItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="city" label="市">
                <a-select v-model="form.city" allowClear style="width: 100%">
                  <a-select-option v-for="item in filteredCityItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="area" label="区">
                <a-select v-model="form.area" allowClear style="width: 100%">
                  <a-select-option v-for="item in filteredAreaItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="detail_address" label="详细地址">
                <a-input v-model="form.detail_address" allowClear />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_date" label="预计出库日">
                <a-date-picker v-model="form.delivery_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="arrival_date" label="预计到货日">
                <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->

            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_method" label="配送方式">
                <!-- <a-select v-model="form.delivery_method" allowClear style="width: 100%" @change="changeDeliveryMethod">
                  <a-select-option v-for="value in getDeliveryMethodList()" :key="value" :value="value">
                    {{ value }}
                  </a-select-option>
                </a-select> -->
                <a-input v-model="form.delivery_method" disabled allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="express" label="承运公司">
                <a-select
                  v-model="form.express"
                  allowClear
                  style="width: 100%"
                  show-search
                  :filter-option="filterExpressOption"
                  @change="changeExpress"
                >
                  <a-select-option v-for="value in getCourierCompanyList()" :key="value" :value="value">
                    {{ value }}
                  </a-select-option>
                </a-select>
                <!-- <a-select v-model="form.express" style="width: 100%" @change="changeExpress">
                  <a-select-option value="顺丰快递">顺丰快递</a-select-option>
                  <a-select-option value="德邦快递">德邦快递</a-select-option>
                  <a-select-option value="德邦物流">德邦物流</a-select-option>
                  <a-select-option value="京东快递">京东快递</a-select-option>
                  <a-select-option value="京东物流">京东物流</a-select-option>
                  <a-select-option value="亚德物流">亚德物流</a-select-option>
                  <a-select-option value="中通快递">中通快递</a-select-option>
                  <a-select-option value="市内配送">市内配送</a-select-option>
                  <a-select-option value="顺丰同城">顺丰同城</a-select-option>
                  <a-select-option value="货拉拉">货拉拉</a-select-option>
                  <a-select-option value="闪送">闪送</a-select-option>
                  <a-select-option value="自提">自提</a-select-option>
                </a-select> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="packaging_requirements" label="包装要求(可多选)">
                <a-select v-model="form.packaging_requirements" mode="multiple" allowClear style="width: 100%">
                  <a-select-option v-for="value in getPackagingRequirementList()" :key="value" :value="value">
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_time" label="送货时间">
                <a-date-picker v-model="form.delivery_time" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->

            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="delivery_files" label="附件">
                <delivery-file-upload v-model="form.delivery_files" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input v-model="materialNumber" placeholder="请扫描产品编号" @keyup.enter.native="scanMaterial" allowClear />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openInventoryModal">选择产品</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="goodsData"
              :pagination="false"
            >
              <div slot="batch" slot-scope="value, item">
                <batch-search-select
                  v-if="!item.isTotal"
                  v-model="item.batch"
                  placeholder="批次"
                  :warehouse="form.warehouse"
                  :material="item.material"
                  size="small"
                  :defaultItem="item"
                >
                </batch-search-select>
              </div>
              <div slot="total_quantity" slot-scope="value, item">
                <div v-if="item.isTotal">{{ item.total_quantity }}</div>
                <a-input-number v-else v-model="item.total_quantity" size="small"></a-input-number>
              </div>
              <div slot="weight" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_weight">
                  {{ NP.times(item.total_quantity, item.material_weight) }}
                </span>
              </div>
              <div slot="volume" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_volume">
                  {{ NP.times(item.total_quantity, item.material_volume) }}
                </span>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeMaterial(item, index)">删除</a-button>
                  <a-button type="primary" @click="copyMaterial(item, index)">复制</a-button>
                  <!-- <a-button @click="autoPicking(item, index)">自动拣配</a-button> -->
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <inventory-select-modal
      v-model="materialModalVisible"
      :warehouse="form.warehouse"
      @select="onSelectMaterial"
      @confirm="onConfirm"
    >
    </inventory-select-modal>
  </div>
</template>

<script>
import { orderTypeOption, reservoirAreaOption, materialOption } from "@/api/option";
import { stockOutOrderCreate, stockOutOrderRetrieve, stockOutOrderMaterials } from "@/api/order";
import moment from "moment";
import NP from "number-precision";
import { batchOption } from "@/api/option";

export default {
  components: {
    DeliveryCustomInput: () => import("@/components/DeliveryCustomInput/"),
    InventorySelectModal: () => import("@/components/InventorySelectModal/index"),
    BatchSearchSelect: () => import("@/components/BatchSearchSelect/index"),
    DeliveryFileUpload: () => import("@/components/DeliveryFileUpload/index"),
  },
  inject: ["getDeliveryMethodList", "getPackagingRequirementList", "getCourierCompanyList"],
  data() {
    return {
      NP,
      form: {
        external_number: `CK${Number(new Date())}`,
        customer_contact: "",
        contact_phone: "",
        receiving_address: "",
        delivery_method: undefined,
      },
      rules: {
        external_number: [
          { required: true, message: "请输入订单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        delivery_date: [{ required: true, message: "请选择出库日期", trigger: "change" }],
        express: [{ required: true, message: "请选择承运公司", trigger: "change" }],
        receiving_address: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },
      moment,
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: "英文名称",
          dataIndex: "material_english_name",
        },
        {
          title: "批次",
          key: "batch",
          scopedSlots: { customRender: "batch" },
          width: 240,
        },
        {
          title: "数量",
          key: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "总体积",
          dataIndex: "volume",
          key: "volume",
          scopedSlots: { customRender: "volume" },
        },
        {
          title: "包装规格",
          dataIndex: "material_spec",
          key: "material_spec",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      tableLoading: false,

      deliveryMaterialItems: [],
      orderTypeItems: [],
      reservoirAreaItems: [],

      userItems: [],
      clientItems: [],
      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,

      provinceItems: [],
      cityItems: [],
      areaItems: [],
    };
  },
  computed: {
    filteredCityItems() {
      const items = [];
      if (this.form.province) {
        for (const item of this.cityItems) {
          if (this.form.province == item.provinceCode) {
            items.push(item);
          }
        }
      }
      return items;
    },
    filteredAreaItems() {
      const items = [];
      if (this.form.city) {
        for (const item of this.areaItems) {
          if (this.form.city == item.cityCode) {
            items.push(item);
          }
        }
      }
      return items;
    },
    goodsData() {
      // 统计合计
      let totalQuantity = 0;
      for (let item of this.deliveryMaterialItems) {
        totalQuantity = NP.plus(totalQuantity, item.total_quantity);
      }
      return [
        ...this.deliveryMaterialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          total_quantity: totalQuantity,
        },
      ];
    },
  },
  methods: {
    initData() {
      const deliveryOrderID = this.$route.query.id;
      if (deliveryOrderID) {
        stockOutOrderRetrieve({ id: deliveryOrderID }).then((data) => {
          this.form = {
            ...this.form,
            customer_contact: data.customer_contact,
            contact_phone: data.contact_phone,
            receiving_address: data.receiving_address,
            delivery_date: data.delivery_date,
            arrival_date: data.arrival_date,
            delivery_method: data.delivery_method,
            express: data.express,
            packaging_requirements:
              data.packaging_requirements && data.packaging_requirements !== "" ? data.packaging_requirements.split(",") : [],
            delivery_time: data.delivery_time,
            remark: data.remark,
          };
        });

        stockOutOrderMaterials({ id: deliveryOrderID }).then((data) => {
          const deliveryMaterialItems = [];
          for (const item of data) {
            deliveryMaterialItems.push({
              id: item.material,
              material: item.material,
              material_name: item.material_item.name,
              material_number: item.material_item.number,
              material_english_name: item.material_item.english_name,

              total_quantity: item.total_quantity,
              unit: item.material_item.unit,
              batch: item.batch,
              material_weight: item.material_item.weight,
              material_volume: item.material_item.volume,
              material_spec: item.material_item.spec,
            });
          }
          this.deliveryMaterialItems = deliveryMaterialItems;
        });
      }

      orderTypeOption({ page_size: 999999 }).then((data) => {
        this.orderTypeItems = data.results;
      });

      reservoirAreaOption({ page_size: 999999 }).then((data) => {
        this.reservoirAreaItems = data.results;
      });
    },
    onConfirm(items) {
      for (const item of items) {
        this.onSelectMaterial(item);
      }
    },
    onSelectMaterial(item) {
      let index = this.deliveryMaterialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn("产品已存在");
        return;
      }

      batchOption({
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        material: item.material,
      }).then((data) => {
        let batchItem = data.results.length > 0 ? data.results[0] : {};
        this.deliveryMaterialItems = this.$functions.insertItem(this.deliveryMaterialItems, {
          id: item.material,
          material: item.material,
          material_name: item.material_name,
          material_number: item.material_number,
          total_quantity: 1,
          unit: item.unit,
          batch: batchItem.id,
          batch_number: batchItem.number,
          material_volume: item.material_volume,
          material_weight: item.material_weight,
          material_spec: item.material_spec,
          material_english_name: item.material_english_name,
        });
      });
    },
    selectDeliveryClient(item) {
      this.form.contact_phone = item.contact_phone;
      this.form.receiving_address = item.receiving_address;
    },
    changeExpress(value) {
      // this.form.delivery_method = "快递";
      const onlineLogistics = ["顺丰快递", "德邦快递", "德邦物流", "京东快递", "京东物流", "亚德物流", "中通快递"];
      const offlineLogistics = ["市内配送", "顺丰同城", "货拉拉", "闪送", "自提"];
      if (onlineLogistics.some((logistics) => logistics === value)) {
        this.form.delivery_method = "线上配送";
      }

      if (offlineLogistics.some((logistics) => logistics === value)) {
        this.form.delivery_method = "线下配送";
      }
    },
    changeDeliveryMethod(value) {
      if (value !== "快递") {
        this.form.express = undefined;
      }
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.deliveryMaterialItems.length == 0) {
            this.$message.warn("未添加产品");
            return;
          }

          for (let item of this.deliveryMaterialItems) {
            if (!item.batch) {
              this.$message.warn("未选择批次");
              return;
            }
          }

          // if (!this.form.delivery_method && !this.form.express) {
          //   this.$message.warn("未选择配送方式或承运公司");
          //   return;
          // }

          // if (this.form.delivery_method === "快递" && !this.form.express) {
          //   this.$message.warn("未选择承运公司");
          //   return;
          // }
          // let provinceIndex = this.provinceItems.findIndex((item) => item.code == this.form.province);
          // let cityIndex = this.cityItems.findIndex((item) => item.code == this.form.city);
          // let areaIndex = this.areaItems.findIndex((item) => item.code == this.form.area);
          // let province = provinceIndex === -1 ? "" : this.provinceItems[provinceIndex].name;
          // let city = cityIndex === -1 ? "" : this.cityItems[cityIndex].name;
          // let area = areaIndex === -1 ? "" : this.areaItems[areaIndex].name;
          // let address = this.form.detail_address || "";
          // let receivingAddress = `${province} ${city} ${area} ${address}`;
          let packagingRequirements = "常规";
          if (Array.isArray(this.form.packaging_requirements) && this.form.packaging_requirements.length > 0) {
            packagingRequirements = this.form.packaging_requirements.join(",");
          }

          let formData = {
            ...this.form,
            // province: this.form.province,
            // city: this.form.city,
            // district: this.form.area,
            // address_detail: address,
            packaging_requirements: packagingRequirements,
            stock_out_material_items: this.deliveryMaterialItems,
            // receiving_address: receivingAddress,
          };
          this.loading = true;
          stockOutOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/order/stock_out_order_list" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning("请扫描产品编号！");
        return false;
      }
      this.tableLoading = true;
      materialOption({ number: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning("没有找到相关产品，请确认扫描的产品编号！");
            return false;
          }
          let item = data.results[0];
          let index = this.deliveryMaterialItems.findIndex((_item) => _item.id == item.id);
          if (index != -1) {
            this.deliveryMaterialItems = this.$functions.replaceItem(this.deliveryMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: this.deliveryMaterialItems[index].total_quantity + 1,
              unit: item.unit,
            });
          } else {
            this.deliveryMaterialItems = this.$functions.insertItem(this.deliveryMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: 1,
              unit: item.unit,
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    openInventoryModal() {
      this.materialModalVisible = true;
    },
    removeMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 0, { ...item });
    },
    autoPicking(item, index) {
      this.tableLoading = true;
      let searchForm = {
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        material: item.material,
      };
      batchOption(searchForm)
        .then((data) => {
          let materialItems = [];
          let totalQuantity = item.total_quantity;
          for (let batchItem of data.results) {
            if (totalQuantity > batchItem.remain_quantity) {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: batchItem.remain_quantity,
              });

              totalQuantity -= batchItem.remain_quantity;
            } else {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: totalQuantity,
              });

              break;
            }
          }
          this.deliveryMaterialItems.splice(index, 1, ...materialItems);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    filterExpressOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
  },
  mounted() {
    this.provinceItems = require("@/utils/provinces.json");
    this.cityItems = require("@/utils/cities.json");
    this.areaItems = require("@/utils/areas.json");
    this.initData();
  },
};
</script>
